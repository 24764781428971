<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">

      <div class="p-4 flex">
        <h1 class="text-3xl">Selecionar Candidatos: {{ vacancyTitle }}</h1>
      </div>

      <!-- steps -->
        <div class="w-full py-6">
          <div class="flex">
            <div class="w-1/4">
              <div class="relative mb-2">
                <div class="w-10 h-10 mx-auto bg-green-600 rounded-full text-lg text-white flex items-center">
                  <span class="text-center text-white w-full">
                    <!-- <h1 class="text-white">1°</h1> -->
                    <i class="ri-file-list-line"></i>
                  </span>
                </div>
              </div>
              <div class="text-xs text-center md:text-base">Em inscrição</div>
            </div>

            <div class="w-1/4">
              <div class="relative mb-2">
                <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                  <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                    <div class="w-0 bg-green-500 py-1 rounded" style="width: 100%;"></div>
                  </div>
                </div>

                <div class="w-10 h-10 mx-auto bg-green-600 rounded-full text-lg text-white flex items-center">
                  <span class="text-center text-white w-full">
                    <svg class="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/>
                      <path d="M12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 8-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6 10.5l-2.939 1.545.561-3.272-2.377-2.318 3.286-.478L18 14l1.47 2.977 3.285.478-2.377 2.318.56 3.272L18 21.5z" fill="rgba(255,255,255,1)"/>
                    </svg>       
                  </span>
                </div>
              </div>

              <div class="text-xs text-center md:text-base">Em Seleção</div>
            </div>

            <div class="w-1/4">
              <div class="relative mb-2">
                <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                  <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                    <div class="w-0 bg-green-500 py-1 rounded" style="width: 60%;"></div>
                  </div>
                </div>

                <div class="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                  <span class="text-center text-gray-600 w-full">
                    <svg class="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/>
                      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z" fill="rgba(75,85,99,1)"/>
                    </svg>
                  </span>
                </div>
              </div>

              <div class="text-xs text-center md:text-base">Agendar entrevista</div>
            </div>

            <div class="w-1/4">
              <div class="relative mb-2">
                <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                  <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                    <div class="w-0 bg-green-500 py-1 rounded" style="width: 0%;"></div>
                  </div>
                </div>

                <div class="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                  <span class="text-center text-gray-600 w-full">
                    <svg class="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                      <path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(75,85,99,1)"/>
                    </svg>
                  </span>
                </div>
              </div>

              <div class="text-xs text-center md:text-base">Candidatos aprovados</div>
            </div>
          </div>
        </div>

      <div class="p-4">
        <div class="flex mb-4 justify-end">
          <button
            @click="notification = !notification"
            class="flex bg-green-1000 text-white px-6 py-3 rounded rounded-md focus:outline-none"
            :class="{ active: notification }"
            v-tooltip="{
                      content:
                        'Selecione caso deseja enviar uma notificação ao talento informando que foi selecionado para a vaga.',
                      placement: 'right'
                    }"
          >
          <h4 v-if="notification">Não notificar <i class="ri-notification-off-line mr-2"></i></h4>
          <h4 v-else>Notificar <i class="ri-notification-4-line mr-2"></i></h4>
          </button>
        </div>
        <v-client-table
          ref="table"
          :data="participants"
          :columns="columns"
          :options="options"
        >
          <div
            class="flex justify-center items-center"
            slot="resume"
            slot-scope="{ row }"
          >
            <router-link
              class="mr-3 text-2xl border-2 border-yellow-500 hover:bg-yellow-500 text-yellow-500 hover:text-white p-1 rounded-lg focus:outline-none focus:shadow-outline"
              :to="{ name: 'curriculum', params: { id: row.id } }"
              v-tooltip="{ content: 'Ver Currículo', placement: 'left' }"
              target="_blank"
            >
              <i class="ri-article-line"></i>
            </router-link>
          </div>
          <div class="flex justify-center" slot="actions" slot-scope="{ row }">
            <input
              type="checkbox"
              :value="row.id"
              v-model="selectedParticipants"
              v-if="row.status != 'Selecionado(a)'"
            />
          </div>
        </v-client-table>
        <div class="flex mb-4 justify-between">
          <button
            v-if="!active"
            type="button"
            class="bg-yellow-400 text-white px-6 py-3 rounded-md focus:outline-none"
            @click="goBack"
          >
            Cancelar
          </button>
          <button
            class="bg-green-1000 text-white px-6 py-3 rounded-md focus:outline-none"
            @click="confirmSelection()"
          >
            Confirmar
          </button>
          <router-link
            v-if="active"
            class="border-2 border-green-1000 hover:bg-green-1000 hover:text-white px-6 justify-end px-6 py-3 rounded rounded-md focus:outline-none"
            :to="{ name: 'schedule-interview' }"
          >
            Avançar etapa
          </router-link>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import axios from '@/utils/axios';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';

export default {
  name: 'SelectParticipant',

  title() {
    return `${process.env.VUE_APP_NAME} | Selecionar candidatos`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  // props: {
  //   id: {
  //     type: Number,
  //     required: true
  //   }
  // },

  data() {
    return {
      isLoading: false,
      vacancyTitle: '',
      active: false,
      notification: true,
      participants: [],
      selectedParticipants: [],
      columns: ['id', 'actions', 'name', 'status', 'resume'],
      options: {
        sortable: true,
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          name: 'Nome',
          document: 'E-mail',
          resume: 'Currículo',
          actions: ' ',
          status: 'Status'
        },
        hiddenColumns: ['id'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count:
            '{from} - {to} de {count} registros|{count} registros|Um registro',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhum registro encontrado.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      }
    };
  },

  methods: {
    toggle() {
      this.notification = !this.enable;
    },

    confirmSelection() {
      if (!this.selectedParticipants.length) {
        this.$toast.warning('Selecione pelo menos um candidato(a)!');
      } else {
        this.$confirm({
          message: 'Confirma os candidatos para a próxima fase?',
          button: {
            no: 'Não',
            yes: 'Sim'
          },
          callback: confirm => {
            if (confirm) {
              this.nextStepInteview();
              this.$toast.success('Candidatos selecionados com sucesso.');
              this.active = true;
            }
          }
        });
      }
    },

    nextStepInteview() {
      const participantsVacancy = {
        participants: [],
        vacancy: [],
        notification: this.notification
      };

      participantsVacancy.vacancy.push(this.$route.params.id);
      participantsVacancy.participants.push(this.selectedParticipants);

      axios
        .post('/api/participante/selecionar-participante', participantsVacancy)
        .then(({ data }) => {
          this.$toast.success(data.data.message);
        })
        .catch(({ response }) => {
          Object.values(response.data.errors).forEach(error => {
            this.$toast.error(...error);
          });
        });
    },

    fecthParticipants() {
      this.isLoading = true;
      axios
        .get(`/api/participante/${this.$route.params.id}`)
        .then(({ data }) => {
          data.participants.forEach(participant => {
            this.participants.push(participant);
            if (participant.status === 'Selecionado(a)') {
              this.active = true;
            }
          });
          this.isLoading = false;
        });
    },

    goBack() {
      this.$router.go(-1);
    },

    fetchVacancy() {
      axios
        .get(`/api/vagas/${this.$route.params.id}/vaga/`)
        .then(({ data }) => {
          this.vacancyTitle = data.data[0].office;
          if (data.data[0].status === 'Agendar e entrevistar') {
            this.active = true;
          }
        });
    }
  },

  created: function() {
    this.fecthParticipants();
    this.fetchVacancy();
  }
};
</script>

<style scoped>
.active {
  background-color: rgb(247, 103, 103);
  color: rgb(255, 255, 255);
  border: 1px solid #3b7e6f;
}
</style>
